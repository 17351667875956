import React from "react"
import { useQuery } from "react-query"
import * as api from "../../context/api"
import { useAuth } from "../../context/auth"
import MyExams from "../app-components/MyExams"
import { motion } from "framer-motion"
import { myAnimations } from "../../context/framer-animations"
import { VscCircleFilled } from "react-icons/vsc"
import Loader from "../../utils/Loader"
import { Link } from "gatsby"

const MyExamsContainer = ({ isViewAllButton = true, limit }) => {
  const { currentUser } = useAuth()

  const {
    data: student,
    isLoading,
    isError,
  } = useQuery(
    ["getStudent", currentUser?.email],
    () => api.getStudent(currentUser?.email),
    {
      enabled: !!currentUser,
    }
  )

  if (isLoading) return <Loader />
  if (isError) return <p>Something went wrong</p>

  const enrollments =
    student && student[0]?.enrollments?.split(",").filter(e => e)

  return (
    <motion.div {...myAnimations.fadeIn} className="">
      {!isLoading && !isError && (
        <div className="flex flex-col mt-4 mb-4 space-y-2 ">
          <div className="flex justify-between mb-2 ">
            <div className="flex items-center space-x-1 ">
              <p className="font-base">My Enrolled Exams</p>
              <VscCircleFilled className="w-2 h-2" />
              <p className="text-sm">{enrollments?.length}</p>
            </div>
            {isViewAllButton && (
              <Link
                to="/app/enrolled-all"
                className="px-3 text-sm align-middle bg-gray-200 rounded-full"
              >
                view all
              </Link>
            )}
          </div>
          <MyExams myexams={enrollments} limit={limit} />
        </div>
      )}
    </motion.div>
  )
}

export default MyExamsContainer
