import React from "react"
import GoalTooltip from "./goal-tool-tip"
import BlankCard from "../app-components/blankCard"
import GoalChallangeCard from "./goal-challange-card"
import PremiumPromotionCard from "./premium-promotion-card"
import WelcomeCard from "./welcome-card"
import QuizCountCard from "./QuizCountCard"
import QuizStatusCard from "./QuizStatusCard"
import _ from "lodash"
import { useQuery } from "react-query"
import * as api from "../../context/api"
import { getAttemptsOnly } from "../../context/graph"
import { useAuth } from "../../context/auth"
import { useProfileStore } from "../../context/profileStore"
import QuizMenu from "./QuizMenu"
import AboutExams from "./AboutExams"
import PopularExams from "../app-components/PopularExams"
import Notifications from "../app-components/NotificationCard"
import { motion } from "framer-motion"
import { myAnimations } from "../../context/framer-animations"
import MyExamsContainer from "../containers/MyExamsContainer"
import ViewAllButton from "../app-components/viewAllBtn"
import { Link } from "gatsby"

const AppHome = () => {
  const { currentUser } = useAuth()
  const setPrevAttempt = useProfileStore(state => state.setPrevAttempt)

  const { data: student } = useQuery(["getStudent", currentUser?.email], () =>
    api.getStudent(currentUser?.email)
  )

  const data = useQuery(
    [["getAttemptsOnly"], currentUser?.email],
    () => getAttemptsOnly(currentUser?.email),
    {
      onSuccess: attempts => {
        if (attempts?.length > 0) {
          setPrevAttempt(attempts)
        } else {
          setPrevAttempt(null)
        }
      },
      enabled: !!currentUser?.email,
    }
  )

  return (
    <motion.div {...myAnimations.fadeIn} className="p-4 pt-4 space-y-4 ">
      <WelcomeCard student={student && student[0]} />
      <div className="mt-6 mb-4 font-base">Last Attempt</div>
      <QuizStatusCard />
      <QuizCountCard />
      <div className="pt-4">
        <MyExamsContainer />
      </div>
      <div className="pt-4 font-base">Practice</div>
      <QuizMenu
        title="Short Tests"
        url="/app/subjecttests"
        iconurl="https://cdn.acadmi.in/quiz/planner_1_575697d12e.png"
      />
      <QuizMenu
        title="Subject Tests"
        url="/app/subjecttests"
        iconurl="https://cdn.acadmi.in/quiz/book_stack_ee2480d0de.png"
      />
      <QuizMenu
        title="Full Tests"
        url="/app/fulltests"
        iconurl="https://cdn.acadmi.in/quiz/online_test_1_d9d8994eb9.png"
      />
      <QuizMenu
        title="Previous Papers"
        url="/app/fulltests"
        iconurl="https://cdn.acadmi.in/quiz/previous_5185f8ab89.png"
      />
      <TitleWithViewAll title="Popular Exams" uri="/app/popular-all" />
      <PopularExams />
      <TitleWithViewAll title="Explore Exams" uri="/app/exams" />
      <AboutExams />
      <div className="pt-8 mb-4 font-base">Goals</div>
      {/* <GoalTooltip value={30} />
      <GoalTooltip value={80} />
      <GoalChallangeCard /> */}
      <BlankCard text="Goals will be available based on your performance in attempted quizzes" />
      <TitleWithViewAll title="Notifications" uri="/app/home" />
      <Notifications type="NOTIFICATION" />
      <div className="pt-8 mb-4"></div>
      <PremiumPromotionCard />
      <div className="pt-8 mb-4"></div>
    </motion.div>
  )
}

function TitleWithViewAll({ title, uri }) {
  return (
    <div className="flex justify-between pt-8 mb-4 ">
      <p className="font-base">{title}</p>
      <Link
        to={uri}
        className="px-3 text-sm align-middle bg-gray-200 rounded-full"
      >
        view all
      </Link>
    </div>
  )
}

export default AppHome
