import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import _ from "lodash"
import { HiArrowNarrowRight } from "react-icons/hi"
import { BiDetail } from "react-icons/bi"
import { useExamsQuery } from "../../context/static-queries/useExamsQuery"

const MyExams = ({ limit = 4, myexams = [] }) => {
  const exams = useExamsQuery()
  let filteredExams = exams

  if (myexams.length) {
    filteredExams = _.filter(filteredExams, exam => {
      return myexams.some(myexam => myexam === exam.examId)
    })
  }

  let MyExams = myexams.length ? filteredExams.slice(0, limit) : []

  return (
    <div className="grid grid-cols-1 gap-2">
      {MyExams.map(exam => (
        <Link
          key={exam.examId}
          to={`/app/exams/${exam.examSlug}`}
          className="flex items-center justify-between p-4 bg-white shadow rounded-xl"
        >
          <div className="flex items-center justify-start">
            <div className="w-12 h-12 mr-4 overflow-hidden">
              <GatsbyImage
                image={exam.icon.localFile.childImageSharp.gatsbyImageData}
                alt={exam.examId}
              />
            </div>
            <div className="flex flex-col cursor-pointer ">
              <div className="flex ">
                <p className="px-2 mb-2 text-xs text-blue-600 bg-blue-100 rounded">
                  {exam?.profession?.sector}
                </p>
              </div>

              <p className="font-base">{exam?.examName}</p>
            </div>
          </div>
          <HiArrowNarrowRight className="w-6 ml-2 text-blue-500" />
        </Link>
      ))}
    </div>
  )
}

export default MyExams
