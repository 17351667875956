import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { useAuth } from "./auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { currentUser } = useAuth()

  useEffect(() => {
    if (!currentUser?.email && location.pathname !== `/login`) {
      navigate("/login")
      return null
    }
  }, [currentUser])

  return <Component {...rest} />
}

export default PrivateRoute
