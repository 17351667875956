import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/context/privateRoute"
import Home from "../../components/app/home"
import AppLayout from "../../components/app/app-components/layout"

const AppHome = ({ path }) => {
  return (
    <AppLayout title="Home" path={path}>
      <Router>
        <PrivateRoute path="/app/home" uri={path} component={Home} />
      </Router>
    </AppLayout>
  )
}

export default AppHome
