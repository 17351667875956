import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const PremiumPromotionCard = () => {
  return (
    <div className="flex justify-between p-4 bg-white shadow rounded-2xl">
      <div className="flex flex-col space-y-6">
        <p className="font-base">Learn even more!</p>
        <p className="text-xs">
          Unlock premium features
          <br />
          only for Rs. <span className="line-through ">699</span> per month.
        </p>
        <span className="mt-2 text-xs text-green-800">
          Enjoy your premium subscription for free
        </span>
        <button onClick={() => navigate("/app/premium")} className="btn">
          Go Premium
        </button>
      </div>
      <div className="flex items-center justify-end mr-4">
        <StaticImage
          src="../../../images/book.svg"
          alt="book"
          placeholder={"tracedSVG"}
        />
      </div>
    </div>
  )
}

export default PremiumPromotionCard
