import React from "react"
import { Link } from "gatsby"
import { MdKeyboardArrowRight } from "react-icons/md"

const QuizMenu = ({ title, examGroup, desc, iconurl, url, color }) => {
  return (
    <Link
      to={url}
      href={url}
      state={{
        examGroup: examGroup,
        url: url,
      }}
      className={`flex p-4 bg-white shadow rounded-xl justify-between items-center  `}
    >
      <div className="flex w-full items-center justify-start ">
        <div className="w-16 object-contain flex justify-center pr-6 ">
          {iconurl && <img src={iconurl} alt={title} />}
        </div>
        <div className="flex flex-col w-2/3 justify-center">
          <p className="font-base text-xl mb-2 ">{title}</p>
        </div>
      </div>
      <MdKeyboardArrowRight className="w-6 h-6" />
    </Link>
  )
}

export default QuizMenu
