import React from "react"

const BlankCard = ({ text }) => {
  return (
    <div className="flex justify-between p-4 py-8 space-x-4 shadow bg-gray-50 rounded-xl">
      <p className="text-sm ">{text}</p>
    </div>
  )
}

export default BlankCard
