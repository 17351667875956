import React from "react"
import _ from "lodash"
import { useProfileStore } from "../../context/profileStore"

const QuizCountCard = () => {
  const prevAttempt = useProfileStore(state => state.prevAttempt)
  const completed = _.filter(prevAttempt, { isComplete: true })
  const inProgress = _.filter(prevAttempt, { isComplete: false })
  return (
    <div className="flex justify-around space-x-2 ">
      <div className="flex w-1/2 p-4 bg-white shadow rounded-xl">
        <div className="inline-flex items-center justify-end space-x-3">
          <p className="text-5xl text-gray-900 font-base">
            {completed?.length}
          </p>
          <p className="text-sm leading-normal ">Quiz Completed</p>
        </div>
      </div>
      <div className="flex w-1/2 p-4 bg-white shadow rounded-xl">
        <div className="inline-flex items-center justify-end space-x-3">
          <p className="text-5xl text-gray-900 font-base">
            {inProgress?.length}
          </p>
          <p className="text-sm leading-normal ">In Progress</p>
        </div>
      </div>
    </div>
  )
}

export default QuizCountCard
