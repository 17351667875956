import React from "react"
import { navigate } from "gatsby"
import { format } from "date-fns"
import _ from "lodash"

import { useProfileStore } from "../../context/profileStore"

function QuizStatusCard() {
  const prevAttempt = useProfileStore(state => state.prevAttempt)

  if (prevAttempt && prevAttempt.length < 1) return <p>No attempts</p>

  const completedAttempts = _.filter(prevAttempt, { isComplete: true })
  let date = completedAttempts[0] && new Date(completedAttempts[0]?.updated_at)
  let paperName = completedAttempts[0]?.paperName
  let attemptDate = date && format(date, "dd/MM/yyyy")

  return (
    <div className="flex items-center justify-between p-4 bg-gray-900 shadow rounded-xl ">
      <div className="flex flex-col text-white ">
        <p className="font-base ">{paperName}</p>
        <p className="text-sm ">{attemptDate}</p>
      </div>

      <button onClick={() => navigate("/app/analysis")} className="btn-light">
        Result
      </button>
    </div>
  )
}

export default QuizStatusCard
