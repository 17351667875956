import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WelcomeCard = ({ student }) => {
  return (
    <div className="relative flex p-4 bg-white shadow rounded-xl">
      <div className="inline-flex flex-col items-start justify-end mt-3">
        <p className="text-2xl text-red-500 font-base">
          Hello {student?.firstName} 👋
        </p>
        <p className="text-sm leading-normal">It’s good to see you again.</p>
      </div>
      <div className="absolute bottom-0 hidden right-5 ">
        <StaticImage
          src="../../../images/mascot.svg"
          alt="mascot"
          placeholder={"tracedSVG"}
        />
      </div>
    </div>
  )
}

export default WelcomeCard
