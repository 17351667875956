import React from "react"

const ProgressBar = ({ value }) => {
  return (
    <div className="relative pt-1">
      <div className="flex h-2 mb-4 overflow-hidden text-xs bg-gray-300 rounded">
        <div
          style={{
            width: `${value}%`,
            backgroundColor: `${value < 50 ? "#f56565" : "#48bb78"}`,
          }}
          className="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap"
        ></div>
      </div>
    </div>
  )
}

export default ProgressBar
