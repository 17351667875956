import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { MdKeyboardArrowRight } from "react-icons/md"

const AboutExams = () => {
  const data = useStaticQuery(query)
  const totalExams = data.allStrapiExams.totalCount
  return (
    <Link
      to="/app/examlist"
      href="/app/exams"
      className="flex justify-between items-center p-4 bg-white shadow rounded-xl"
    >
      <div className="flex justify-start">
        <div className="w-28 object-contain flex justify-center pr-6 ">
          <img
            src="https://acadmind.s3.ap-south-1.amazonaws.com/quiz/adventurer_91e60db2e7.png"
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center space-y-2">
          <p className="font-base text-xl ">Explore exams</p>
          <p className="text-md font-semibold text-gray-500">
            {totalExams}+ Exams
          </p>
          <div className="flex hidden justify-start items-center w-30">
            <div className="w-8 h-8 -mr-2 bg-red-400 rounded-full"></div>
            <div className="w-8 h-8 -mr-2 bg-blue-400 rounded-full"></div>
            <div className="w-8 h-8 -mr-2 bg-purple-400 rounded-full"></div>
            <div className="w-8 h-8 -mr-2 bg-yellow-400 rounded-full"></div>
            <div className="w-8 h-8 -mr-2 bg-green-400 rounded-full"></div>
            <p className="ml-4">75+</p>
          </div>
        </div>
      </div>
      <MdKeyboardArrowRight className="w-6 h-6" />
    </Link>
  )
}

export const query = graphql`
  {
    allStrapiExams {
      totalCount
    }
  }
`

export default AboutExams
